import { IAPIResponse } from './../types/interfaces/index';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Obj, RetainerAgreementResponse } from '../types/interfaces';

interface IUserService {
  inviteUser: (body: Obj) => Promise<IAPIResponse>;
  updateUser: (body: Obj) => Promise<IAPIResponse>;
  getUser: (
    rows: number,
    page: number,
    sortBy: string,
    orderBy: string,
    search: string,
    filter: string
  ) => Promise<IAPIResponse<{ count: number; businessAdminUsers: Obj[] }>>;
  deleteUser: (id: string) => Promise<IAPIResponse>;
  getBusinessPermission: () => Promise<IAPIResponse<Obj>>;
  updateBusinessPermission: (name: string, data: Obj) => Promise<IAPIResponse>;
  changePassword: (data: Obj) => Promise<IAPIResponse>;
  changeEmail: (data: { mail: string }) => Promise<IAPIResponse>;
  verifyEmailChange: (token: string) => Promise<IAPIResponse>;
  getRetainerAgreement: () => Promise<IAPIResponse<RetainerAgreementResponse>>;
  updateRetainerAgreement: (data: Obj) => Promise<IAPIResponse>;
  createRetainerAgreement: (data: Obj) => Promise<IAPIResponse>;
  updateMfa: (data: Obj) => Promise<IAPIResponse>;
  getMfa: () => Promise<IAPIResponse>;
  deactivateEmployee: (body: any, id: string) => Promise<IAPIResponse>;
  getEmailStatus: () => Promise<IAPIResponse>;
  myPermissions: () => Promise<IAPIResponse<Obj[]>>;
  deleteEmployee: (id: string, data: { date: string }) => Promise<IAPIResponse>;
  hardDeleteEmployee: (id: string) => Promise<IAPIResponse>;
  getNotification: (
    row: number,
    page: number,
    filter: string
  ) => Promise<IAPIResponse>;
  getNotificationStatus: () => Promise<IAPIResponse>;
  getUnreadMessage: () => Promise<IAPIResponse>;
  uploadProfileImage: (formData: FormData) => Promise<IAPIResponse>;
  uploadCompanyLogo: (
    formData: FormData,
    companyId: string
  ) => Promise<IAPIResponse>;
  getProfileDetails: () => Promise<IAPIResponse>;
  updateProfileDetails: (editData: Obj) => Promise<IAPIResponse>;
  deleteNotification: (id: string) => Promise<IAPIResponse>;
  getOtp: (data: Obj) => Promise<IAPIResponse>;
  verifyOtp: (otp: string) => Promise<IAPIResponse>;
  reSendEmail: (
    type: string,
    companyId: string,
    userId: string
  ) => Promise<IAPIResponse<any>>;
  downloadUserProfilePdf: (data: Obj) => Promise<IAPIResponse<Obj>>;
  getBannerNotification: () => Promise<IAPIResponse>;
    bulkInvite:(id:string)=> Promise<IAPIResponse>;
  getPreSignedUrl: (companyId: string, data: Obj) => Promise<IAPIResponse<Obj>>;
  updateOnboardingUploadHistory: (
    companyId: string,
    data: Obj
  ) => Promise<IAPIResponse<Obj>>;
  deleteOnboardingUploadHistory: (id: string) => Promise<IAPIResponse<Obj>>;
  fetchOnboardingUploadHistory: (
    companyId: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string
  ) => Promise<IAPIResponse<{ count: number; onBoardingUploadHistory: Obj[] }>>;

  getOnBoardingUsers: (
    id: string,
    rows: number,
    page: number,
    sortBy: string,
    orderBy: string,
    search?: string,
    filter?: string
  ) => Promise<IAPIResponse<{ count: number; onBoardingUsers: Obj[] }>>;
  updateOnBoardUserDetails: (
    companyId: string,
    data: Obj
  ) => Promise<IAPIResponse>;
  onBoardUser: (companyId: string, userId?: string) => Promise<IAPIResponse>;
  deleteOnBoardUser: (id: string) => Promise<IAPIResponse>;
  getOnBoardUserData: (companyId: string) => Promise<IAPIResponse>;
  getOnBoardingFileDownloadUrl: (fileURL: string) => Promise<IAPIResponse<Obj>>;
  companyActivation: (body: Obj, id: string) => Promise<IAPIResponse>;
  companyHardDeletion: (id: string) => Promise<IAPIResponse>;
  companySoftDeletion: (id: string) => Promise<IAPIResponse>;
}

const userService = (httpClient: AxiosInstance): IUserService => {
  return {
    companySoftDeletion: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/admin/agency/company/delete/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to Delete Company.',
          data: undefined,
        };
      }
    },
    companyHardDeletion: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/admin/agency/company/delete/permanent/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to Delete Company.',
          data: undefined,
        };
      }
    },
    companyActivation: async (body, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/admin/company/activation/${id}`,
          body
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to Deactivate Company.',
          data: undefined,
        };
      }
    },
    inviteUser: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/business/admin',
          body
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to send mail.' };
      }
    },
    updateUser: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/business/admin/${body._id}`,
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid' };
      }
    },
    getUser: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter
    ): Promise<IAPIResponse<{ count: number; businessAdminUsers: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/business/admins?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to send mail.' };
      }
    },
    deleteUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/business/admin/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid' };
      }
    },
    getBusinessPermission: async (): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/business/role'
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get permissions',
          data: undefined,
        };
      }
    },
    updateBusinessPermission: async (
      name,
      data
    ): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/business/role/${name}`,
          data
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to update permissions',
          data: undefined,
        };
      }
    },
    changePassword: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/change-password',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to change password.',
          data: undefined,
        };
      }
    },
    changeEmail: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/change-mail',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to change email.',
          data: undefined,
        };
      }
    },
    verifyEmailChange: async (token): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/mail/verification/${token}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to verify', data: undefined };
      }
    },
    getRetainerAgreement: async (): Promise<
      IAPIResponse<RetainerAgreementResponse>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/retainer-document'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get retainer agreement',
          data: undefined,
        };
      }
    },
    updateRetainerAgreement: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company/retainer-upload',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update retainer agreement',
          data: undefined,
        };
      }
    },
    createRetainerAgreement: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/company/retainer-document',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update retainer agreement',
          data: undefined,
        };
      }
    },
    updateMfa: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/update/mfa',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update multi-factor authentication.',
          data: undefined,
        };
      }
    },
    getMfa: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/mfa/details'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get multi-factor authentication.',
          data: undefined,
        };
      }
    },
    deactivateEmployee: async (body, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `app/user/deactivate/user/${id}`,
          body
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to Deactivate Employee.',
          data: undefined,
        };
      }
    },
    getEmailStatus: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/changed/email/data'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to Deactivate Employee.',
          data: undefined,
        };
      }
    },
    myPermissions: async (): Promise<IAPIResponse<Obj[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/business/my/role'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get permissions.',
          data: [],
        };
      }
    },
    deleteEmployee: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/employee/delete/${id}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete employee.',
        };
      }
    },
    hardDeleteEmployee: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/employee/delete/permanent/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete employee.',
        };
      }
    },
    getNotification: async (row, page, filter): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `app/user/notifications/list?page=${page}&rows=${row}&filter=${filter}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get notification.',
        };
      }
    },
    getNotificationStatus: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/new/notifications'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get notification.',
        };
      }
    },
    getUnreadMessage: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/unread/messages'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get notification.',
        };
      }
    },
    uploadProfileImage: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/upload-profile-img',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to upload Image.',
        };
      }
    },
    getProfileDetails: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/profile/details'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to fetch details.',
        };
      }
    },
    updateProfileDetails: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/update/profile/details',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to fetch details.',
        };
      }
    },
    uploadCompanyLogo: async (data, companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/company/upload-logo/${companyId}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to upload Image.',
        };
      }
    },
    deleteNotification: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/notification/delete/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete notification.',
        };
      }
    },
    getOtp: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/re-new/mobile',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get otp.',
          data: undefined,
        };
      }
    },
    verifyOtp: async (otp): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/mobile/update/${otp}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update.',
          data: undefined,
        };
      }
    },
    reSendEmail: async (
      type,
      companyId,
      userId
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/re-send-email?type=${type}&companyId=${companyId}&userId=${userId}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Error.',
        };
      }
    },
    downloadUserProfilePdf: async (data): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/profile/pdf/download',
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    getBannerNotification: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/banner/notification/by/user/role'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get notification.',
        };
      }
    },
    getPreSignedUrl: async (
      companyId,
      data
    ): Promise<IAPIResponse<RetainerAgreementResponse>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/pre/signed/url/upload/s3/${companyId}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get Pre Signed Url.',
        };
      }
    },
    getOnBoardingUsers: async (
      companyId,
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter
    ): Promise<IAPIResponse<{ count: number; onBoardingUsers: Obj[] }>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/on/boarding/users/${companyId}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to fetch user details.' };
      }
    },
    onBoardUser: async (companyId, userId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/on/boarding/users/${companyId}?userId=${userId}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to On Board users.' };
      }
    },
    getOnBoardUserData: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/on/boarding/user/view/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to fetch Board users.' };
      }
    },
    updateOnBoardUserDetails: async (
      companyId,
      data
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/on/boarding/user/update/${companyId}`,
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to update.' };
      }
    },
    deleteOnBoardUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/company/on/boarding/user/delete/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to On Board users.' };
      }
    },
    updateOnboardingUploadHistory: async (
      companyId,
      data
    ): Promise<IAPIResponse<RetainerAgreementResponse>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/on/boarding/upload/history/${companyId}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed create upload history.',
        };
      }
    },
    deleteOnboardingUploadHistory: async (
      id
    ): Promise<IAPIResponse<RetainerAgreementResponse>> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/user/company/on/boarding/upload/history/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete upload history.',
        };
      }
    },
    fetchOnboardingUploadHistory: async (
      companyId,
      rows,
      page,
      sortBy,
      orderBy
    ): Promise<
      IAPIResponse<{ count: number; onBoardingUploadHistory: Obj[] }>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/on/boarding/upload/history/${companyId}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to fetch upload history.',
        };
      }
    },
    getOnBoardingFileDownloadUrl: async (
      fileURL
    ): Promise<IAPIResponse<RetainerAgreementResponse>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company/on/boarding/file/download/url',
          { fileURL }
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed fetch url.',
        };
      }
    },
    bulkInvite: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/company/bulk/invite',{companyId:id}
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
  };
};

export default userService;
